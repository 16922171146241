







































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import UserNotificationTypeTable from '@/components/user_notifications/UserNotificationTypeTable.vue';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { UserNotificationTypeDetails } from '@/models/user_notification_types';
import { UserNotificationStores } from '@/store/user_notifications';
import { PaginationDetails } from '@/models/generic';
import PaginationControls from '@/components/common/PaginationControls.vue';
import SearchBar from '@/components/common/SearchBar.vue';

@Component({
  components: {
    Page,
    UserNotificationTypeTable,
    EmptyPlaceholder,
    PaginationControls,
    SearchBar,
  },
})
export default class UserNotificationTypeManagementPage extends Vue {
  userNotificationStore = UserNotificationStores;

  currentPage = 1;
  currentLimit = 10;

  get isCurrentRouteManagement() {
    return this.$route.path === `/notification_settings`;
  }

  redirectToNotificationTypes() {
    if (this.$route.path !== `/notification_settings`) {
      this.$router.push({
        path: `/notification_settings`,
      });
    }
  }

  redirectToNotificationBatch() {
    if (this.$route.path !== `/notification_batches`) {
      this.$router.push({
        path: `/notification_batches`,
      });
    }
  }

  get userNotificationTypes() {
    return this.userNotificationStore?.userNotificationTypes;
  }

  mounted() {
    this.fetchData();
  }

  fetchData() {
    this.userNotificationStore.getUserNotificationTypes({
      page: this.currentPage,
      limit: this.currentLimit,
    });
  }

  onChangePage(params: PaginationDetails) {
    this.currentPage = params.page;
    this.currentLimit = params.limit;
    this.fetchData();
  }

  handleEdit(data: UserNotificationTypeDetails) {
    this.$router.push({ path: `notification_settings/${data.id}/edit` });
  }

  handleDelete(data: UserNotificationTypeDetails) {
    this.$buefy.dialog.confirm({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete <b>${data.type}</b>?`,
      onConfirm: () => {
        this.userNotificationStore.deleteUserNotificationType(data.id);
      },
    });
  }

  @Watch(`userNotificationStore.isDeletingUserNotificationType`)
  checkDeleteResponse() {
    if (!this.userNotificationStore.isDeletingUserNotificationType) {
      this.fetchData();
    }
  }
}
