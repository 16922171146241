




























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { PaginatedUserNotificationTypes } from '@/models/user_notification_types';
import moment from 'moment';

@Component({})
export default class UserNotificationTypeTable extends Vue {
  get moment() {
    return moment;
  }

  @Prop()
  tableData!: PaginatedUserNotificationTypes['data'];

  @Emit(`edit`)
  editItem(item: any) {
    return item;
  }

  @Emit(`delete`)
  deleteItem(item: any) {
    console.log(item)
  }
}
