import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateUserNotificationTypeFormPayload,
  UpdateUserNotificationTypeFormPayload,
  UserNotificationTypeResponse,
  UserNotificationTypesResponse,
  PaginatedUserNotificationTypes,
} from '@/models/user_notification_types';
import { SendUserNotificationFormPayload, UserNotificationResponse } from '@/models/user_notifications';
import {
  CreateUserNotificationBatchFormPayload,
  UpdateUserNotificationBatchFormPayload,
  UserNotificationBatchResponse,
  UserNotificationBatchesResponse,
  PaginatedUserNotificationBatches,
} from '@/models/user_notification_batches';

const defaultPaginatedUserNotificationTypes = {
  data: null,
  page: 1,
  limit: 10,
  totalRecords: 0,
  totalPages: 0,
};

const defaultPaginatedUserNotificationBatches = {
  data: null,
  page: 1,
  limit: 10,
  totalRecords: 0,
  totalPages: 0,
};

@Module({
  namespaced: true,
  dynamic: true,
  name: `user_notification`,
  store,
})
class UserNotificationStore extends VuexModule {
  isPendingUserNotificationTypes = false;
  isPendingUserNotificationType = false;
  isCreatingUserNotificationType = false;
  isUpdatingUserNotificationType = false;
  isDeletingUserNotificationType = false;
  isSendingUserNotification = false;
  isPendingUserNotificationBatches = false;
  isPendingUserNotificationBatch = false;
  isCreatingUserNotificationBatch = false;
  isUpdatingUserNotificationBatch = false;
  isDeletingUserNotificationBatch = false;

  userNotificationTypesError: any = null;
  userNotificationTypeError: any = null;
  createUserNotificationTypeError: any = null;
  updateUserNotificationTypeError: any = null;
  deleteUserNotificationTypeError: any = null;
  sendUserNotificationError: any = null;
  userNotificationBatchesError: any = null;
  userNotificationBatchError: any = null;
  createUserNotificationBatchError: any = null;
  updateUserNotificationBatchError: any = null;
  deleteUserNotificationBatchError: any = null;
  userNotificationTypes: PaginatedUserNotificationTypes = {
    ...defaultPaginatedUserNotificationTypes,
  };
  userNotificationType: UserNotificationTypeResponse['data'] | null = null;
  userNotificationBatches: PaginatedUserNotificationBatches = {
    ...defaultPaginatedUserNotificationBatches,
  };
  userNotificationBatch: UserNotificationBatchResponse['data'] | null = null;

  @Mutation
  GET_USER_NOTIFICATION_TYPES_LOADING() {
    this.isPendingUserNotificationTypes = true;
    this.userNotificationTypesError = null;
    this.userNotificationTypes = defaultPaginatedUserNotificationTypes;
  }
  @Mutation
  GET_USER_NOTIFICATION_TYPES_SUCCESS(payload: PaginatedUserNotificationTypes) {
    this.isPendingUserNotificationTypes = false;
    this.userNotificationTypes = {
      ...this.userNotificationTypes,
      ...payload,
    };
  }
  @Mutation
  GET_USER_NOTIFICATION_TYPES_ERROR(payload: any) {
    this.isPendingUserNotificationTypes = false;
    this.userNotificationTypesError = payload;
  }

  @Mutation
  GET_USER_NOTIFICATION_TYPE_LOADING() {
    this.isPendingUserNotificationTypes = true;
    this.userNotificationTypeError = null;
    this.userNotificationType = null;
  }
  @Mutation
  GET_USER_NOTIFICATION_TYPE_SUCCESS(payload: UserNotificationTypeResponse['data']) {
    this.isPendingUserNotificationType = false;
    this.userNotificationType = payload;
  }
  @Mutation
  GET_USER_NOTIFICATION_TYPE_ERROR(payload: any) {
    this.isPendingUserNotificationType = false;
    this.userNotificationTypeError = payload;
  }

  @Mutation
  CREATE_USER_NOTIFICATION_TYPE_LOADING() {
    this.isCreatingUserNotificationType = true;
    this.createUserNotificationTypeError = null;
  }
  @Mutation
  CREATE_USER_NOTIFICATION_TYPE_SUCCESS(payload: any) {
    this.isCreatingUserNotificationType = false;
  }
  @Mutation
  CREATE_USER_NOTIFICATION_TYPES_ERROR(payload: any) {
    this.isCreatingUserNotificationType = false;
    this.createUserNotificationTypeError = payload;
  }

  @Mutation
  UPDATE_USER_NOTIFICATION_TYPE_LOADING() {
    this.isUpdatingUserNotificationType = true;
    this.updateUserNotificationTypeError = null;
  }
  @Mutation
  UPDATE_USER_NOTIFICATION_TYPE_SUCCESS() {
    this.isUpdatingUserNotificationType = false;
  }
  @Mutation
  UPDATE_USER_NOTIFICATION_TYPES_ERROR(payload: any) {
    this.isUpdatingUserNotificationType = false;
    this.updateUserNotificationTypeError = payload;
  }

  @Mutation
  DELETE_USER_NOTIFICATION_TYPE_LOADING() {
    this.isDeletingUserNotificationType = true;
    this.deleteUserNotificationTypeError = null;
  }
  @Mutation
  DELETE_USER_NOTIFICATION_TYPE_SUCCESS() {
    this.isDeletingUserNotificationType = false;
  }
  @Mutation
  DELETE_USER_NOTIFICATION_TYPES_ERROR(payload: any) {
    this.isDeletingUserNotificationType = false;
    this.deleteUserNotificationTypeError = payload;
  }

  @Mutation
  SEND_USER_NOTIFICATION_LOADING() {
    this.isSendingUserNotification = true;
    this.sendUserNotificationError = null;
  }

  @Mutation
  SEND_USER_NOTIFICATION_SUCCESS() {
    this.isSendingUserNotification = false;
  }

  @Mutation
  SEND_USER_NOTIFICATION_ERROR(payload: any) {
    this.isSendingUserNotification = false;
    this.sendUserNotificationError = payload;
  }

  @Mutation
  GET_USER_NOTIFICATION_BATCHES_LOADING() {
    this.isPendingUserNotificationBatches = true;
    this.userNotificationBatchesError = null;
    this.userNotificationBatches = defaultPaginatedUserNotificationBatches;
  }
  @Mutation
  GET_USER_NOTIFICATION_BATCHES_SUCCESS(payload: PaginatedUserNotificationBatches) {
    this.isPendingUserNotificationBatches = false;
    this.userNotificationBatches = {
      ...this.userNotificationBatches,
      ...payload,
    };
  }
  @Mutation
  GET_USER_NOTIFICATION_BATCHES_ERROR(payload: any) {
    this.isPendingUserNotificationBatches = false;
    this.userNotificationBatchesError = payload;
  }

  @Mutation
  GET_USER_NOTIFICATION_BATCH_LOADING() {
    this.isPendingUserNotificationBatches = true;
    this.userNotificationBatchError = null;
    this.userNotificationBatch = null;
  }
  @Mutation
  GET_USER_NOTIFICATION_BATCH_SUCCESS(payload: UserNotificationBatchResponse['data']) {
    this.isPendingUserNotificationBatch = false;
    this.userNotificationBatch = payload;
  }
  @Mutation
  GET_USER_NOTIFICATION_BATCH_ERROR(payload: any) {
    this.isPendingUserNotificationBatch = false;
    this.userNotificationBatchError = payload;
  }

  @Mutation
  CREATE_USER_NOTIFICATION_BATCH_LOADING() {
    this.isCreatingUserNotificationBatch = true;
    this.createUserNotificationBatchError = null;
  }
  @Mutation
  CREATE_USER_NOTIFICATION_BATCH_SUCCESS(payload: any) {
    this.isCreatingUserNotificationBatch = false;
  }
  @Mutation
  CREATE_USER_NOTIFICATION_BATCHES_ERROR(payload: any) {
    this.isCreatingUserNotificationBatch = false;
    this.createUserNotificationBatchError = payload;
  }

  @Mutation
  UPDATE_USER_NOTIFICATION_BATCH_LOADING() {
    this.isUpdatingUserNotificationBatch = true;
    this.updateUserNotificationBatchError = null;
  }
  @Mutation
  UPDATE_USER_NOTIFICATION_BATCH_SUCCESS() {
    this.isUpdatingUserNotificationBatch = false;
  }
  @Mutation
  UPDATE_USER_NOTIFICATION_BATCHES_ERROR(payload: any) {
    this.isUpdatingUserNotificationBatch = false;
    this.updateUserNotificationBatchError = payload;
  }

  @Mutation
  DELETE_USER_NOTIFICATION_BATCH_LOADING() {
    this.isDeletingUserNotificationBatch = true;
    this.deleteUserNotificationBatchError = null;
  }
  @Mutation
  DELETE_USER_NOTIFICATION_BATCH_SUCCESS() {
    this.isDeletingUserNotificationBatch = false;
  }
  @Mutation
  DELETE_USER_NOTIFICATION_BATCHES_ERROR(payload: any) {
    this.isDeletingUserNotificationBatch = false;
    this.deleteUserNotificationBatchError = payload;
  }

  @Action
  getUserNotificationTypes({ keyword = '', page = 1, limit = 10 }) {
    this.context.commit(`GET_USER_NOTIFICATION_TYPES_LOADING`);

    axios
      .get<UserNotificationTypesResponse>(`/user-notification/all-types`, {
        params: {
          keyword,
          page,
          limit,
        },
      })
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_USER_NOTIFICATION_TYPES_SUCCESS`, {
            data: response.data.data.objects,
            page: response.headers[`x-page`],
            limit: response.headers[`x-limit`],
            totalRecords: response.headers[`x-total-records`],
            totalPages: response.headers[`x-total-pages`],
          });
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching notification type data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`GET_USER_NOTIFICATION_TYPES_ERROR`, error);
      });
  }

  @Action
  getUserNotificationType(id: number) {
    this.context.commit(`GET_USER_NOTIFICATION_TYPE_LOADING`);

    axios
      .get<UserNotificationTypeResponse>(`/user-notification/type/${id}`)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_USER_NOTIFICATION_TYPE_SUCCESS`, response.data.data.objects);
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching notification type data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`GET_USER_NOTIFICATION_TYPE_ERROR`, error);
      });
  }

  @Action
  createUserNotificationType(params: CreateUserNotificationTypeFormPayload) {
    this.context.commit(`CREATE_USER_NOTIFICATION_TYPE_LOADING`);

    axios
      .post<UserNotificationTypeResponse>(`/user-notification/type`, params)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`CREATE_USER_NOTIFICATION_TYPE_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created notification type.`,
          });
        }

        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating notification type data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`CREATE_USER_NOTIFICATION_TYPE_ERROR`, error);
      });
  }

  @Action
  updateUserNotificationType(payload: UpdateUserNotificationTypeFormPayload) {
    this.context.commit(`UPDATE_USER_NOTIFICATION_TYPE_LOADING`);

    axios
      .put<UserNotificationTypeResponse>(
        `/user-notification/type/${payload.id}`,
        { ...payload },
        { timeout: 60000 }
      )
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`UPDATE_USER_NOTIFICATION_TYPE_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Notification type successfully update!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with notification type update process. Please try again. Error: ${response.data.message}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`UPDATE_USER_NOTIFICATION_TYPE_ERROR`, error);
      });
  }

  @Action
  deleteUserNotificationType(id: number) {
    this.context.commit(`DELETE_USER_NOTIFICATION_TYPE_LOADING`);

    axios
      .delete(`/user-notification/type/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`DELETE_USER_NOTIFICATION_TYPE_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-info`,
            duration: 3000,
            message: `Notification type successfully deleted!`,
          });
        }

        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with notification type delete process. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`DELETE_USER_NOTIFICATION_TYPES_ERROR`, error);
      });
  }

  @Action
  sendUserNotification(payload: SendUserNotificationFormPayload) {
    this.context.commit(`SEND_USER_NOTIFICATION_LOADING`);

    axios
      .post<UserNotificationResponse>(`/user-notification`, { ...payload }, { timeout: 60000 })
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`SEND_USER_NOTIFICATION_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Badge successfully assigned!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with send notification process. Please try again. Error: ${response.data.error}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`SEND_USER_NOTIFICATION_ERROR`, error);
      });
  }

  @Action
  getUserNotificationBatches({ keyword = '', page = 1, limit = 10 }) {
    this.context.commit(`GET_USER_NOTIFICATION_BATCHES_LOADING`);

    axios
      .get<UserNotificationBatchesResponse>(`/user-notification/all-batches`, {
        params: {
          keyword,
          page,
          limit,
        },
      })
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_USER_NOTIFICATION_BATCHES_SUCCESS`, {
            data: response.data.data.objects,
            page: response.headers[`x-page`],
            limit: response.headers[`x-limit`],
            totalRecords: response.headers[`x-total-records`],
            totalPages: response.headers[`x-total-pages`],
          });
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching notification batch data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`GET_USER_NOTIFICATION_BATCHES_ERROR`, error);
      });
  }

  @Action
  getUserNotificationBatch(id: number) {
    this.context.commit(`GET_USER_NOTIFICATION_BATCH_LOADING`);

    axios
      .get<UserNotificationBatchResponse>(`/user-notification/batch/${id}`)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_USER_NOTIFICATION_BATCH_SUCCESS`, response.data.data.objects);
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching notification batch data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`GET_USER_NOTIFICATION_BATCH_ERROR`, error);
      });
  }

  @Action
  createUserNotificationBatch(params: CreateUserNotificationBatchFormPayload) {
    this.context.commit(`CREATE_USER_NOTIFICATION_BATCH_LOADING`);

    axios
      .post<UserNotificationBatchResponse>(`/user-notification/batch`, params)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`CREATE_USER_NOTIFICATION_BATCH_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created notification batch.`,
          });
        }

        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating notification batch data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`CREATE_USER_NOTIFICATION_BATCH_ERROR`, error);
      });
  }

  @Action
  updateUserNotificationBatch(payload: UpdateUserNotificationBatchFormPayload & { id: number }) {
    this.context.commit(`UPDATE_USER_NOTIFICATION_BATCH_LOADING`);
    const {id, ...rest} = payload;

    axios
      .put<UserNotificationBatchResponse>(
        `/user-notification/batch/${id}`,
        { ...rest },
        { timeout: 60000 }
      )
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`UPDATE_USER_NOTIFICATION_BATCH_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Notification batch successfully update!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with notification batch update process. Please try again. Error: ${response.data.message}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`UPDATE_USER_NOTIFICATION_BATCH_ERROR`, error);
      });
  }

  @Action
  deleteUserNotificationBatch(id: number) {
    this.context.commit(`DELETE_USER_NOTIFICATION_BATCH_LOADING`);

    axios
      .delete(`/user-notification/batch/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`DELETE_USER_NOTIFICATION_BATCH_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-info`,
            duration: 3000,
            message: `Notification batch successfully deleted!`,
          });
        }

        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with notification batch delete process. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`DELETE_USER_NOTIFICATION_BATCHES_ERROR`, error);
      });
  }
}

export const UserNotificationStores = getModule(UserNotificationStore);
